.w-full {
    width: 100%;
  }
  .w-max-150 {
    max-width: 100px;
  }
  .flex {
    display: flex;
  }
  .block {
    display: block;
  }
  .z-999 {
    z-index: 999;
  }
  .top-0 {
    top: 0px;
  }
  .sticky {
    position: sticky;
  }
  .px-4{
    padding-left: 1rem;
    padding-right: 1rem;

  }
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-center{
    justify-content: center;
  }
  .justify-end {
    justify-content: end !important;
  }
  .items-center {
    align-items: center;
  }
  .items-end {
    align-items: end;
  }
  .text-left{
    text-align: left;
  }
  .text-right{
    text-align: right;
  }
  .flex-col{
    flex-direction: column;
  }
  .flex-row{
    flex-direction: row;
  }
  .ml-4 {
    margin-left: 1rem;
  }
  
  .mb-4 {
    margin-bottom: 1rem;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .gap-1\.5 {
    gap: 0.375rem;
  }
  
  .gap-10 {
    gap: 10px;
  }
  .gap-20 {
    gap: 20px;
  }
  .H-100{
    // height: 100%;
  }
  
  .ease-in-out {
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  .duration-300 {
    transition-duration: 0.3s;
  }
  .MainComp {
    position: relative;
    margin: auto;
    padding: 1.5rem;
    width: 80vw;
  }
  .JobsListMain{
    
  }
  .LoadingIndicator{
    padding: 20px;
  }
  .SkeletonContainer{
    // padding: 0 20px;
  }


  @media print {
    .table-container-print {
      overflow: visible !important; 
      width: 100%; 
    }
  
    .table-container-print-table {
      width: 100%; 
    }
  }