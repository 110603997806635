body {
  margin: 0;

  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input:focus {
    outline: none;
  }
}
html::-webkit-scrollbar {
  display: none;
}
html,
body,
#root {
  height: 100%;
  // overflow-x: hidden;
  a {
    text-decoration: none;
  }
}
.JobPageActive {
  &.H-100 {
    height: 100%;
  }
  .H-100 {
    height: 100%;
  }
}

.App {
  height: inherit;
  background-color: var(--Job-Page) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --bg-color: #f6f7fa;
  --sidebar-bg: #fff;
}
.light-theme {
  --user-bg-color: rgba(245, 247, 255, 1);
  // --bg-color: #f5f7fa;
  --bg-color: #ffffff;
  --sidebar-bg: #fff;
  --card-bg: #fff;
  --Job-Page: #f2f5fe;
  --Job-Table-border: #ccc;
  --card-border: 1px solid #dfeaf2;
  --logo-color: #343c6a;
  --menu-text-color: rgba(15, 12, 47, 1);
  --item-head-text: #718ebf;
  --item-sub-text: #343c6a;
  --card-head: #343c6a;
  --tab-text-color-active: #1814f3;
  --tab-text-color-inactive: #718ebf;
  --table-th: #718ebf;
  --table-td: #2b3674;
  --border-color: #dfeaf2;
  --login-labels: #000;
  --active-button: #d7f6e0;
  --active-button-color: #36d462;
  --settings-submit-button: rgba(24, 20, 243, 0.5);
  --settings-cancel-button: #ff6666;
  --searchbar: #fff;
  --searchbar-placeholder: #8ba3cb;
  --settings-placeholder: #718ebf;
  --bg-color-big: #e0e8ff;
  --menu-item-active: #1d4ed8;
  --login-input-text: #4d3564;
  --details-input-border: #0f0c2f30;
  --menu-active-bg: rgba(0, 53, 240, 0.05);
  --login-input: #fff;
  --login-input-disabled: #ffffff30;
  --login-input-border: rgba(224, 229, 242, 1);
  --divider: rgba(0, 0, 0, 1);
  --setting-input: #dfeaf2;
  --setting-input-background: #fff;
  --details-head: #0f0c2f;
  --details-card-bg: #fff;
  --details-card-head: rgba(15, 12, 47, 1);
  --details-card-content: rgba(15, 12, 47, 1);
  --Table-Field-Names: #0f0c2f;
  --details-Table-Field-Data: #0f0c2f70;
  --details-tab-bg: rgba(199, 213, 255, 1);
  --details-tab-content: rgba(15, 12, 47, 1);
  --popup-border: #fff;
  --joblist-filter: #737373;
  --sidebar-border: rgba(204, 204, 204, 1);
  --jobs-arrow: #0035f0;
  --pagination-disabled-text: #1814f350;
  --quote-sheet-card: rgb(229 234 253);
  --quote-sheet-bg: rgba(255, 255, 255, 1);
  --quote-sheet-activebg: rgba(0, 53, 240, 0.0509803922);
  --option-placeholder: #0f0c2f4f;
  --diable-bg: rgb(215, 246, 224);
  --input-divider: #0f0c2f4d;
  --item-total: #0f0c2f40;
  --edit-job: #fff;
  --edit-job-bg: #1d4ed870;
  --edit-job-bghover: #1d4ed850;
  --quotation-tabs: #f5f5f5;
  --quotation-tabs-hover: #e0e0e0;
  --chebox-border: #000;
  --show-more-text: #111c44;
  --filter-upload: #222;
  --filter-status-option: #1814f342;
  --filter-button: #374151;
  --status-bg: #f0f8ff;
  ---border-invicible: #ffffff;
}

.dark-theme {
  --status-bg: #3a458170;
  --filter-button: #fff;
  --filter-status-option: #4682b4;
  --filter-upload: #0035f0;
  --chebox-border: #dfeaf220;
  --show-more-text: #fff;
  --quotation-tabs-hover: rgba(197, 194, 194, 0.108);
  --edit-job: #ffff;
  --edit-job-bg: #0035f0;
  --edit-job-bghover: #0035f0;
  --item-total: rgb(255 255 255 / 25%);
  --input-divider: #dfeaf220;
  --diable-bg: rgb(255, 255, 255);
  --option-placeholder: #fff;
  --quote-sheet-activebg: rgba(197, 194, 194, 0.108);
  --quote-sheet-bg: #111c44;
  --pagination-disabled-text: #ccccccde;
  --user-bg-color: #0b1337;
  --quotation-tabs: #0b1337;
  --joblist-filter: #ccc;
  --popup-border: #ccc;
  --menu-active-bg: #343c6a;
  --details-tab-content: #fff;
  --details-tab-bg: #343c6a;
  --quote-sheet-card: #343c6a;
  --Job-Page: #0b1337;
  --Job-Table-border: #0b1337;
  --bg-color: #0b1337;
  --sidebar-bg: #111c44;
  --card-bg: #111c44;
  --card-border: 1px solid #dfeaf220;
  --logo-color: #fff;
  --menu-text-color: #ffffffa2;
  --item-head-text: #fff;
  --item-sub-text: #fff;
  --card-head: #fff;
  --tab-text-color-active: #fff;
  --tab-text-color-inactive: #888;
  --table-th: #888;
  --table-td: #fff;
  --border-color: #dfeaf220;
  --login-labels: #fff;
  --active-button: #55a842;
  --active-button-color: #fff;
  --settings-submit-button: #1814f3;
  --settings-cancel-button: #d22f2f;
  --searchbar: #3a4581;
  --searchbar-placeholder: #ffffff;
  --settings-placeholder: #c3c3c3;
  --bg-color-big: #1b254b;
  --menu-item-active: #fff;
  --login-input: #343c6a;
  --login-input-disabled: #343c6a60;
  --login-input-border: #343c6a;
  --login-input-text: #ffffff;
  --divider: #e0e8ff;
  --setting-input: #343c6a;
  --setting-input-background: #343c6a;
  --details-head: #fff;
  --details-card-bg: #111c44;
  --details-card-head: #fff;
  --details-card-content: #fff;
  --Table-Field-Names: #fff;
  --details-Table-Field-Data: #fff;
  --details-input-border: rgb(149, 149, 149);
  --sidebar-border: rgba(133, 132, 132, 0.726);
  --jobs-arrow: #fff;
  ---border-invicible: #111c44;
}

.dark-theme {
  main {
    background-color: #0b1337;
  }
}

.App {
  // background-color: var(--bg-color);
}
.DefaultCompOverflow {
  // height:100vh;
  overflow: auto;
  &.user {
    background-color: var(--Job-Page) !important;
  }
  background-color: var(--bg-color) !important;

  // }
  &.JobPage {
    min-height: 100vh;
    background-color: var(--Job-Page) !important;
  }
}
.-translate-x-full {
  transform: translateX(-100%);
}
.translate-x-0 {
  transform: translateX(0px);
}
.MainSidebar {
  // transition-timing-function: linear;
  // transition-duration: 300ms;
  background-color: var(--sidebar-bg);
  overflow-y: hidden;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  display: flex;
  top: 0px;
  left: 0px;
  position: fixed;
}
.LogoFont {
  font-size: 25px;
  font-weight: 900;
  color: var(--logo-color);
  margin-bottom: 0 !important;
  img {
    width: 200px;
  }
}
.DefaultCompOverflow {
  // background-color: var(--bg-color);
}
.Logo {
  padding: 10px 30px;
}
.MenuItems {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  ul {
    list-style-type: none;
    li {
      a {
        font-size: 14px;
        font-weight: 700;
        color: var(--menu-text-color);
      }
    }
  }
}

.HeaderMain {
  background-color: var(--card-bg);

  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: solid 1px var(--sidebar-border);
}
.headericons {
  width: 30px;
  height: 30px;
  background: var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}
.MainSidebar {
  border-right: solid 1px var(--bg-color);
  border: 1px solid var(--sidebar-border);
  // box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.1);
  // box-shadow: 6px 1px 17px rgba(0, 0, 0, 0.1);;
}
.UtilsMain {
  display: flex;
  gap: 30px;
  align-items: center;
  width: fit-content;
  // background-color: var(--card-bg);
  border-radius: 30px;
  margin: 20px;
  input[type="text"] {
    float: right;
    padding: 6px;
    margin-right: 16px;
    border: none;
    font-size: 17px;
    border-radius: 50px;
    background-color: var(--bg-color);
    padding-left: 20px;
    outline: none;
  }
  .Profile {
    img {
      // width: 20px;
      // height: 20px;
      width: 30px;
      height: 30px;
      border-radius: 50px;
    }
  }
}

.CardOne {
  // border: 1px solid #dfeaf2;
  border: var(--card-border);
  width: auto;
  border-radius: 20px;

  background: var(--card-bg);
  .Sec {
    justify-content: space-between;
    .cardsec {
      display: grid;
      gap: 3px;
      width: 50%;
      text-align: left;
    }
  }
  .Details {
    display: grid;
    gap: 30px;
  }
}

.CardDetails {
  height: 100%;
  border: var(--card-border);
  width: 320px;
  border-radius: 20px;
  background: var(--card-bg);
  .Head {
    padding: 20px 20px 10px 20px;

    border-bottom: 1px solid var(--border-color);
  }
  .Sec {
    justify-content: space-between;
    .cardsec {
      display: grid;
      gap: 3px;
      width: 50%;
      text-align: left;
    }
  }
  .Details {
    display: grid;
    gap: 20px;
    padding: 20px;
  }
}
.CardHead {
  font-size: 22px;
  font-weight: 600;
  line-height: 27px;
  color: var(--card-head);
}

.ItemHead {
  font-size: 12px;
  line-height: 14px;
  color: var(--item-head-text);
}
.ItemSub {
  color: var(--item-sub-text);
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
}
.Section {
  text-align: left;
  width: 32%;
}
.KPIDetailsJob {
  display: flex;
  // justify-content: space-between;
  // overflow-x: auto;
  gap: 20px;
}
::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 1024px) {
  .AddJobForm .AddJobsComp.AddVariation {
    left: auto;
    right: 20px;
  }
  .JobTable table thead tr th.SiteName {
    min-width: 235px;
  }
  .ProfileEditPage {
    flex-wrap: wrap;
  }

  .detailstablist {
    .css-heg063-MuiTabs-flexContainer {
      overflow: auto;
    }
  }
  .KPIDetailsJob {
    overflow-x: auto;
    gap: 20px;
  }
}
@media screen and (min-width: 1700px) {
  main {
    width: 80%;
    margin: auto;
  }
}

.KPITabs {
  margin-top: 40px;
  .MuiTabs-root {
    .MuiTabs-indicator {
      background-color: #1814f3 !important;
      height: 3px !important;
      border-radius: 5px 5px 0 0;
    }
  }
  .MuiTabPanel-root {
    padding: 24px 0;
  }
  .MuiButtonBase-root {
    color: var(--tab-text-color-inactive);
    font-size: 14px;
    font-weight: 600;

    &.Mui-selected {
      color: var(--tab-text-color-active);
    }
  }
}
.LabourInsights {
  overflow: auto;
}
.QuoteSheetKpi {
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  table {
    // table-layout: fixed;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    thead {
      border-bottom: 1px solid #e9edf7;
    }
  }
  th {
    font-size: 14px;
    line-height: 24px;
    color: var(--table-th);
    font-weight: 500;
    padding: 20px 10px 10px 10px;
    min-width: 100px;
    font-family: "Inter", sans-serif;
    &:first-child {
      padding-left: 25px;
    }
  }
  td {
    color: var(--table-td);
    font-size: 14px;
    font-weight: 700;
    padding: 10px;
    min-width: 100px;
    font-family: "Inter", sans-serif;
    input {
      background: none;
      width: 100px;
      border-radius: 3px;
      font-size: 14px;
      font-weight: 700;
      color: var(--table-td);
      font-family: "Inter", sans-serif;
      border: 1px solid #dfeaf2;
    }
    &:first-child {
      padding-left: 25px;
    }
  }
  tr:last-child {
    td {
      padding-bottom: 30px;
    }
  }
}

.LabourHour {
  text-align: left;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  .CardHead {
    padding: 10px 0 30px 0;
  }
  .CardOne {
    margin-top: 20px;
    padding: 20px;
  }
  .LabourHourTracking {
    width: 45%;
  }
  .MetricsChart {
    width: 45%;
  }
}

.TableHeadings,
.TableData {
  display: flex;
  text-align: left;
  .Items {
    width: 100px;
    padding: 10px;
  }
  .Items200 {
    width: 200px;
    padding: 10px;
  }
}
.LabourUtil {
  .CardOne {
  }
}
.TableHeadings {
  // padding: 20px;
  border-bottom: 1px solid #e9edf7;
}
.TableData {
  // padding: 20px;
}

.ItemLong {
  width: 200px;
}

.TableCard {
  .CardHead {
    text-align: left;
    padding: 30px 20px 0 20px;
  }
}
.LabourProgressSection,
.OtherInputTableSection {
  margin-top: 40px;
}
.View {
  cursor: pointer;
  background: #f4f7fe;
  color: #4318ff;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  padding: 5px 25px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
  border: 1px solid #0035f0;
  &:hover {
    box-shadow: rgb(204, 219, 232, 0.5) 3px 3px 6px 0px inset,
      rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }
}
.apexcharts-legend-text {
  color: var(--item-head-text) !important;
}
.apexcharts-xaxis {
  text {
    fill: var(--item-head-text) !important;
  }
}
// .settinginp{
//   position: absolute;
//     margin-top: 14px;
//     left: 8rem;
// }
.ACTIVEtab {
  font-size: 12px;
  font-weight: 600;
  width: fit-content;
  padding: 3px 10px;
  border-radius: 5px;
  background-color: var(--active-button);
  color: var(--active-button-color);
}
@media screen and (max-width: 1300px) {
  .KPIDetailsJob {
    overflow-x: auto;
    gap: 20px;
  }
}
@media screen and (min-width: 1100px) {
  .MainSidebar {
    position: fixed;
  }
  .DefaultCompOverflow {
    padding-left: 250px;
    width: -webkit-fill-available !important;
  }
  .MainComp {
    padding: 0 !important;
    width: 75vw !important;
  }
  .breadcrumb-nav {
    margin-top: -24px;
  }
  main {
    // margin-top: 20px;
    // margin-bottom: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    // padding: 20px;
    // height: 100vh;
    // overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .-translate-x-full {
    transform: translateX(0px);
  }
}
@media screen and (min-width: 1700px) {
  .MainComp {
    width: auto !important;
  }
}

// login
.loginhero {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.login-container {
  display: flex;
  height: 100vh;
  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #4a00e0, #8e2de2);
}
.login-left {
  margin: auto;
  width: 50%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--item-head-text);

  form {
    zoom: 80%;
    background: var(--bg-color);
    padding: 20px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border: 1px solid #4d3564;
    height: 500px;
    overflow: auto;
    // .relative {
    //   margin-right: 20px;
    // }
    .signinlogo {
      width: 300px;
      display: flex;
      margin: auto;
      margin-bottom: 40px;
      overflow: auto;
    }
    h1 {
      font-size: 36px;
      font-weight: 700;
      text-align: center;
      line-height: 56px;
      margin: 0px;
      color: var(--table-td);
      // color: white;
    }
    h2 {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      line-height: 56px;
      margin: 0px;
      color: var(--table-td);
      // color: white;
    }
    p {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      color: var(--table-td);
    }
    input {
      width: 410px;
      height: 50px;
      border-radius: 16px;
      border: 1px solid var(--login-input-border);
      padding-left: 50px;
      background-color: var(--login-input);
      color: var(--login-input-text) !important;
    }
    input:focus {
      color: var(--login-input-text) !important;
    }
    input::placeholder {
      padding: 0px 5px;
      color: #a3aed0;
    }
    .submit-button {
      cursor: pointer;
      // background: #271b3290;
      background: #4318ff;
      height: 54px;
      border-radius: 16px;
      margin: 30px 0px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.login-left form input:-webkit-autofill,
.login-left form input:-webkit-autofill:hover,
.login-left form input:-webkit-autofill:focus,
.login-left form input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--login-labels) !important;
}

.loginlabel {
  // display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.02em;
  text-align: left;
  padding: 20px 0px;
  color: var(--login-labels);
}
.login-right {
  width: 50%;
  // background-image: url("./assets/images/signin-Background.svg");
  // background: #4776E6;  /* fallback for old browsers */
  // background: -webkit-linear-gradient(to right, #8E54E9, #4776E6);  /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to right, #8E54E9, #4776E6); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0px 0px 0px 200px;
}
.logoName {
  font-size: 92px;
  color: #fff;
}

.themelogologin {
  right: 50px;
  bottom: 40px;
  position: absolute;
}
.loginhero {
  .HeaderMain {
    display: none;
  }
}
.ShowPass {
  position: absolute;
  margin-left: 10px;
  margin-top: 15px;
}
@media screen and (max-width: 1300px) {
  .login-right {
    display: none;
  }
  .loginhero {
    // padding: 20px 20px;
  }
  .login-left {
    width: 100%;
    padding: 0px;
    // input{
    //   width: 100% !important;
    // }
  }
}
@media screen and (max-width: 1380px) {
}
.sidebarbtn,
.ArrButton {
  color: var(--login-labels);
  display: flex;
  justify-content: right;
}
@media screen and (max-width: 480px) {
  .SearchJobs {
    order: -1;
    flex: 1 0 10%;
  }
  .chargeoutspace {
    margin-bottom: 50px !important;
  }
  .JobDetailsHeader {
    font-size: 12px !important;
    line-height: 17px !important;
    margin-top: -10px;
  }
  .TableNew {
    overflow: auto;
    .TableFieldData {
      overflow: visible !important;
    }
  }
  .UploadBar {
    display: flex;
    flex-wrap: wrap;
    // margin-left: -20px;
    margin-top: -52px;
  }
  .login-left {
    input {
      width: 90% !important;
    }
  }
  .UtilsMain {
    input[type="text"] {
      display: none;
    }
  }

  .Invoicingalign {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    .ItemHead {
      width: 260px;
    }
  }
}
@media screen and (min-width: 481px) and (max-width: 1200px) {
  .Invoicingscrolltab {
    overflow: scroll;
  }
  .Invoicingalign {
    gap: 20px;
  }
}
@media screen and (min-width: 1300px) {
  .ArrButton,
  .sidebarbtn {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .LabourHour {
    .LabourHourTracking,
    .MetricsChart {
      width: 100%;
    }
  }
  .LabourHour {
    flex-wrap: wrap;
  }
  .UploadSec {
    flex-wrap: wrap;
  }
  .profiledtata {
    display: none;
  }
}
.relative {
  position: relative;
}
.UploadSec {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .BackButton {
    cursor: pointer;
    a {
      color: #343c6a;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.UploadFileSec {
  cursor: pointer;
  position: relative;
}
.UploadFileSec .HiddenFile {
  opacity: 0;
}
.UploadFileSec .UploadFileSecSvg {
  gap: 10px;
  align-items: center;
  color: var(--tab-text-color-active);
  font-size: 14px;
  position: absolute;
  right: 0px;
  cursor: pointer;
}
.UploadFileSec .Loading {
  animation: infiniteCircular 6s linear infinite;
}
@keyframes infiniteCircular {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.CancelButton {
  background: none;
  border: none;
  color: var(--tab-text-color-active);
  cursor: pointer;
}
.CircularProgressBar {
  color: var(--tab-text-color-active) !important;
}

/* Container for the whole progress bar */
.progress-bar-container {
  position: relative;
  width: 80%;
  height: 10px;
}

/* Progress bar background */
.progress-bar {
  display: flex;
  position: relative;
  height: 100%;
  background-color: #eff4fb;
  border-radius: 5px;
  overflow: hidden;
}

/* Filled portion of the bar (normal progress) */
.progress-bar-fill {
  height: 100%;
  background-color: #4318ff;
  transition: width 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Exceeded portion (when actual > estimated) */
.progress-bar-exceeded {
  height: 100%;
  background-color: #ff4d4d;
  transition: width 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-bar-label {
  color: white;
  font-weight: bold;
  font-size: 8px !important;
  z-index: 1;
  pointer-events: none;
}
.approved {
  color: #fa5252;
  font-weight: bold;
  width: max-content;
  border-radius: 3px;
  border: solid 0px transparent;
  padding: 5px;
  height: 25px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  &[disabled] {
    // background-color: #d3d3d375 !important;
    // opacity: 60%;
    filter: grayscale(1);
    box-shadow: none;
    // opacity: 60%;
    background: var(--diable-bg) !important;
    cursor: not-allowed;
  }
}
.JobPagination {
  width: fit-content;
  margin: auto;
  margin-right: 0;
  margin-top: 20px;
  padding: 10px 20px;
  button {
    background: none;
    border: none;
    color: var(--tab-text-color-active);
    font-size: 13px;
    font-weight: 600;
    &[disabled] {
      // color: #1814f350;
      color: var(--pagination-disabled-text);
    }
    cursor: pointer;
    min-width: 30px;
    height: 30px;
    border-radius: 7px;
    &.active {
      color: white;
      background-color: #1814f3;
    }
    &:first-child,
    &:last-child {
      svg {
        margin-bottom: -2px;
        margin-left: 10px;
        margin-right: 10px;
        fill: var(--tab-text-color-active);
      }
    }
  }
}
.BackButton {
  p {
    color: var(--item-sub-text);
  }
}
.SearchJobs {
  background: var(--searchbar);
  // border-radius: 40px;
  // border: none;
  padding: 10px 17px !important;
  color: var(--item-sub-text);
  // margin-left: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
  border: 1px solid #cdd9ff;
  padding: 8px 10px;
  border-radius: 20px;
  &::placeholder {
    color: var(--searchbar-placeholder);
    font-weight: 500;
  }
}
@media screen and (max-width: 380px) {
  .SearchJobs {
    margin-left: 0px;
    width: -webkit-fill-available;
    // margin-top: 10px;
  }
}
.HoverCard:hover {
  overflow: hidden;
  cursor: pointer;
  background: linear-gradient(107.38deg, #4c49ed 2.61%, #0a06f4 101.2%);
  .Head {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    border-bottom: 1px solid #ffffff00;
  }
  .CardHead {
    color: white;
  }
  .ItemHead {
    color: #ffffff80;
  }
  .ItemSub {
    color: white;
  }
}

/* Menu Item Styling */
.menu-item {
  position: relative;

  // margin-bottom: 20px;
  display: flex;
  align-items: center;
  .text {
    font-size: 14px;
    font-weight: 500;
  }
}

.menu-item a {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  text-decoration: none;
  color: #aaa;
  transition: all 0.3s ease;
}

.menu-item.active {
  color: var(--menu-item-active);
  font-weight: 700;
  background: var(--menu-active-bg);
}

// .menu-item.active::before {
//   content: "";
//   position: absolute;
//   left: -40px;
//   width: 5px;
//   height: 100%;
//   background-color: #1d4ed8;
//   border-radius: 0px 5px 5px 0px;
// }

.menu-item a:hover {
  color: #1d4ed8;
}
.profiledtata {
  color: var(--table-td);
  font-size: 14px;
  font-weight: 400;
}
.TextHeaderRed {
  background: #ff6666;
  color: white !important;
}
@media screen and (max-width: 480px) {
  .clientcard,
  .lastupdate {
    min-width: -webkit-fill-available;
  }
  .ProfileEditPage {
    flex-wrap: wrap;
  }
  .ProfileEditPage {
    .EditDet {
      form {
        .FormSec {
          flex-wrap: wrap;
        }
      }
    }
  }
}
.EditImage {
  position: relative;
  width: 220px;
  height: fit-content;
  .ProfileImage {
    width: 130px;
    height: 130px;
    border-radius: 100%;
    border: 1px solid #eeeeee;
    object-fit: cover;
  }
  .EditButton {
    position: absolute;
    right: 40px;
    bottom: 20px;
    cursor: pointer;
    input {
      position: absolute;
      width: 40px;
      opacity: 0;
      height: 40px;
      cursor: pointer;
    }
    input::-webkit-file-upload-button {
      cursor: pointer;
    }
  }
}
.ProfileEditPage {
  font-family: "Inter", sans-serif;

  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin: 30px 20px;

  .EditDet {
    width: -webkit-fill-available;
    form {
      .FormSec {
        display: flex;
        gap: 30px;
        // margin-right: 20px;
        margin-bottom: 20px;
        .InputDiv {
          text-align: left;
          width: 100%;
          .Label {
            // color: #232323;
            color: var(--login-labels);
            font-size: 16px;
            margin-bottom: 10px;
          }
          input {
            border: 1px solid var(--setting-input);
            // border: 1px solid #343c6a;
            background: var(--setting-input-background);
            // border-radius: 15px;
            // color: #fff;
            // background: #fff;
            border-radius: 15px;
            height: 50px;
            padding: 0 20px;
            width: -webkit-fill-available;
            color: var(--item-sub-text);

            &::placeholder {
              // color: #718ebf;
              color: var(--settings-placeholder);
              // font-size: 12px;
            }
            .date-class::-webkit-calendar-picker-indicator {
              filter: invert(1);
            }
          }
        }
      }
    }
  }
}
.ButtonPrimary {
  background: var(--settings-submit-button);
  color: white;
  height: 40px;
  border-radius: 10px;
  min-width: 130px;
  width: fit-content;
  border: none;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  font-weight: 600;
}
.ButtonCancel {
  background: var(--settings-cancel-button);
  color: white;
  height: 40px;
  border-radius: 10px;
  width: 130px;
  border: none;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  font-weight: 600;
}
.ResetPass {
  .FormSec {
    // margin-left: 20px;
  }
}

.themelogin {
  background: linear-gradient(135deg, #868cff 0%, #4318ff 100%);
  width: 60px;
  height: 60px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 6px 5px 2px 1px rgb(0 0 0 / 20%);
}
.HeaderPopup {
  position: absolute;
  top: 100%;
  right: 0px;
  // background-color: white;
  background-color: var(--card-bg);
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 20px;
  margin-top: 8px;
  z-index: 1000;
  min-width: 220px;
  .Name {
    font-weight: bold;
    color: var(--card-head);
  }
  .Email {
    word-break: break-all;
    font-size: 14px;
    color: var(--card-head);
  }
  ul {
    padding: 0;
    margin: 0;
    a {
      color: var(--card-head);
      svg {
        fill: var(--card-head);
      }
    }
  }
}

.Invoicing {
  //   background: var(--bg-color-big);
  // padding: 20px;
  display: grid;
  gap: 20px;
}
.ProjectStatus td:first-child {
  min-width: 150px;
}

@media screen and (max-width: 480px) {
  .ButtonPrimary {
    margin-top: 10px;
  }
  .ButtonCancel,
  .ButtonPrimary {
    width: -webkit-fill-available;
  }
  .CardDetails {
    width: 250px;
    height: 95%;
    .CardHead {
      font-size: 18px;
    }
    .ItemSub {
      font-size: 12px;
    }
    .apexcharts-legend-series {
      margin: 5px 0px !important;
    }
  }
  .LabourHourTracking,
  .MetricsChart,
  .QuoteSheetKpi {
    .CardHead {
      font-size: 18px;
    }
  }
}

.Summarisation {
  .cardsec {
    display: flex !important;
    align-items: center;
    gap: 10px;
  }
  .Details {
    gap: 20px !important;
  }
}

.Summarisation {
  input {
    background: none;
    width: 100px;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 700;
    color: var(--table-td);
    font-family: "Inter", sans-serif;
    border: 1px solid #dfeaf2;
  }
}

// .Divider {
//   background: var(--divider);
// }

.TableNew {
  display: flex;

  .TableFieldData {
    min-width: 15%;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 6px;
      display: block;
      height: 6px;
    }
    &::-webkit-scrollbar-track {
      background: #e9e9e9;

      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #0035f080;
      border-radius: 30px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #0035f060;
    }
    &.TableFieldValues {
      .DataTable {
        text-align: left;
        border-radius: 0px 6px 6px 0px;
        border-left: 0 !important;
      }
    }
    .DataTable {
      gap: 12px;
      display: flex;
      // border-style: solid;
      // border-top: 1px;
      // border-bottom: 1px;
      // border-right: 1px;
      // border-left: 0px;
      // border-color: var(--details-input-border);
      border: 1px solid var(--details-input-border);
      border-radius: 6px;
      height: 40px;
      align-items: center;
      padding: 0 20px;
      width: fit-content;
      margin-bottom: 13px;

      .Item {
        min-width: 110px;
        color: var(--Table-Field-Names);
        font-size: 14px;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        &.Total {
          min-width: 120px;
          font-weight: 700;
          border-right: 1px solid var(--item-total);
        }
      }
      &.Heading {
        text-align: left;
        border: 1px solid #00000000;
        align-items: end;
        height: auto;
        .Item {
          &.Total {
            color: var(--details-Table-Field-Data);
          }
          .Second {
            color: var(--details-Table-Field-Data);
            font-weight: 800;
          }
          .First {
            margin-bottom: 10px;
            color: #959595;
            font-size: 12px;
            letter-spacing: 0.39px;
          }
        }
      }
      &.selected {
        margin-bottom: 230px !important;
      }
    }
  }
  .TableFieldNames {
    // min-width: 213px;
    // width: 213px;
    // display: flex;
    .Item.subitem {
      font-weight: 400 !important;
    }
    .Item {
      line-height: 16.94px;
      border: 1px solid #00000000;
      margin-bottom: 14px;
      color: var(--Table-Field-Names);
      font-size: 14px;
      font-weight: 700;
      height: 40px;
      display: grid;
      align-items: center;
      font-family: "Inter", sans-serif;
      &.divider {
        border-bottom: 2px solid #0f0c2f60;
        height: 10px;
        width: 90%;
        margin-bottom: 20px;
      }
      &:not(.fixedheightheading) {
        display: flex;
        position: relative;
      }
      &.selected {
        margin-bottom: 230px !important;
      }
    }
  }
}
.Divider {
  border: 1px solid var(--input-divider);
}
.dividertable {
  height: 20px;
  margin-bottom: 15px;
}

// .Divider{
//   background: var(--divider);
// }
.fixedheightheading {
  gap: 10px !important;
  .Item .Second {
    text-align: left !important;
  }
}
.VariationKPIJobDatarow {
  gap: 10px !important;
}
.labourcard {
  background-color: var(--details-card-bg);
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15);

  .TableNew {
    width: 90%;
    margin: auto;
    // gap: 10px;
  }
  .cardHead {
    font-weight: 700;
    font-size: 16px;
    color: var(--login-labels);
    font-family: "Inter", sans-serif;
    margin-bottom: 10px;
    justify-content: left;
    display: flex;
  }
  .cardTitle {
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    color: rgba(149, 149, 149, 1);
    margin-bottom: 5px;
  }
  .cardcontent {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: var(--details-card-content);
  }
  .divider {
    border-bottom: solid 1px var(--divider);
    opacity: 0.2;
    margin-bottom: 13px;
  }
  .cardsubtitle {
    font-family: "Inter", sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 15.73px;
    text-align: left;
    // margin-top: 5px;
  }
  .LabourStat {
    margin-top: 10px;
    .subtitle {
      font-family: "Inter", sans-serif;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      margin-top: -12px;
      color: var(--details-card-head);
    }
    .content {
      font-family: "Inter", sans-serif;
      font-size: 9px;
      font-weight: 400;
      color: var(--details-card-head);
      opacity: 50%;
    }
  }
}
.labourbar {
  font-family: "Inter", sans-serif;
  font-size: 7.01px;
  font-weight: 500;
  line-height: 8.48px;
  text-align: left;
  color: var(--details-card-head);
}
.green {
  color: #00b300 !important;
}
.yellow {
  background-color: rgba(255, 184, 0, 1);
  height: 14px;
  width: 23px;
  border-radius: 3px;
}
.reddata {
  color: rgba(255, 67, 89, 1) !important;
}
.red {
  background-color: rgba(255, 67, 89, 1);
  height: 14px;
  width: 23px;
  border-radius: 3px;
}
.detailsname {
  // margin-top: 40px;
  .css-1gsv261 {
    border: 1px solid transparent !important;
    border-radius: 17.5px;
    background: var(--details-tab-bg);
    width: 580px;
    height: 41px;
  }
  .labourselected,
  .materialselected {
    transition: background-color 0.3s ease;
    border-radius: 17px;
    margin: 10px 5px;
    height: 24px;
    min-height: 24px;
    background: rgba(255, 255, 255, 1);
    color: rgba(15, 12, 47, 1) !important;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 800 !important;
    line-height: 14.52px;
    font-family: "Inter", sans-serif;
    box-shadow: 0px 4px 4px 0px rgba(0, 53, 240, 0.2);
  }
  Button.MuiButtonBase-root {
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    text-align: left;
    font-family: "Inter", sans-serif;
    padding: 12px 10px;
    text-transform: capitalize;
    color: var(--details-tab-content);
    text-decoration: none;
  }
  .css-13xfq8m-MuiTabPanel-root {
    padding: 24px 0px !important;
  }
}
.css-1sj2jcg-MuiTabs-indicator {
  display: none;
}

// .css-1yalo2m-MuiTabs-scroller{
//   border-radius: 17.5px;
//   background: rgba(199, 213, 255, 1);
// }
.css-1hs7rf2-JoyCircularProgress-progress {
  transform: rotate(88deg) !important;
}
.ProjectChartDiv {
  height: 430px;
  width: 100%;
  // margin-top: 10px;
}
.custom-marker {
  width: 20px;
  height: 20px;
}

.ProjectCostChart {
  .apexcharts-xaxis text {
    fill: #959595 !important;
  }
}
progress[value] {
  appearance: none;
  height: 5px;
  background-color: rgba(218, 218, 218, 1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
}

progress[value]::-webkit-progress-bar {
  background-color: #e0e0e0;
}

progress[value]::-webkit-progress-value {
  background-color: red;
}

progress[value]::-moz-progress-bar {
  background-color: red;
}
.LabourAnalysis {
  margin-top: 50px;
}
.labourspace {
  margin-top: 10px;
}
.LabourInvoice {
  .projectcostsec {
    justify-content: space-between;
    gap: 42px;
  }
}

.red-text {
  color: #ff4359;
}
.ProjectCostChart {
  .apexcharts-tooltip {
    box-shadow: 5px 5px 30px 0px #0035f01a;
    border: none !important;
    padding: 5px 0;
    .apexcharts-tooltip-marker {
      box-shadow: 0px 2.67px 5.33px 0px #3232470f;

      box-shadow: 0px 2.67px 2.67px 0px #3232470f;
      border: 4px solid #ffffff;
      width: 15px;
      height: 12px;
      border-radius: 12px;
    }
    .apexcharts-tooltip-series-group {
      .apexcharts-tooltip-text-y-label {
        display: none;
      }
      .apexcharts-tooltip-y-group {
        padding: 0;
      }
      .apexcharts-tooltip-text-y-value {
        font-size: 14px;
        font-weight: 600;
        font-family: "Inter", sans-serif;
      }
    }
  }

  .apexcharts-legend-text {
    color: var(--Table-Field-Names) !important;
    letter-spacing: 0.39px;
  }
}

.JobDetailsHeader {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--details-head);
  flex-wrap: wrap;
  word-spacing: 2.2px;
  .Sec {
    margin-right: 40px;
  }
}
.NewJob {
  zoom: 80%;
  .JobTable {
    .TableRow {
      display: flex;
      margin-bottom: 15px;
      align-items: center;
      justify-content: space-between;
      width: fit-content;
      &.Header {
        padding: 5px 20px;
        .Item {
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          color: #8c8c8c;
        }
      }
      &.DataTable {
        height: 46px;
        box-shadow: 0px 1px 3px 1px #00000026;
        padding: 5px 20px;
        border-radius: 5px;
        // box-shadow: 0px 1px 2px 0px #0000004D;

        .Item {
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          color: #0f0c2f;
        }
      }
    }
  }
}
.TargetMargin {
  .MuiCircularProgress-progress {
    stroke: rgba(255, 184, 0, 1);
  }
  .iconcircle {
    width: 24px;
    height: 24px;
    background-color: rgba(242, 245, 254, 1);
    display: flex;
    justify-content: center;
    border-radius: 100px;
    img {
      width: 11px;
      margin-right: 1px;
    }
  }
}
.GainLoss {
  .MuiCircularProgress-progress {
    stroke: rgba(54, 212, 98, 1);
  }
  .iconcircle {
    width: 24px;
    height: 24px;
    background-color: rgba(196, 255, 196, 1);
    display: flex;
    justify-content: center;
    border-radius: 100px;
    img {
      width: 11px;
      // margin-right: 1px;
    }
  }
}
.ActualMargin {
  .MuiCircularProgress-progress {
    stroke: rgba(255, 67, 90, 1);
  }
  .iconcircle {
    width: 24px;
    height: 24px;
    background-color: rgba(255, 218, 229, 1);
    display: flex;
    justify-content: center;
    border-radius: 100px;
    img {
      width: 11px;
      // margin-right: 1px;
    }
  }
}
.css-zitgxn-JoyCircularProgress-track {
  --CircularProgress-trackColor: rgba(0, 0, 0, 0);
}

.W-200 {
  min-width: 200px;
  width: 200px;
}
.W-100 {
  min-width: 100px;
  width: 100px;
}
.W-120 {
  min-width: 120px;
  width: 120px;
}
.W-80 {
  min-width: 80px;
  width: 80px;
}
.W-300 {
  min-width: 300px;
  width: 300px;
}
.ViewButton {
  border: 1px solid #0035f0;
  background: #f5f7ff;
  font-size: 12px;
  color: #0035f0;
  width: fit-content;
  cursor: pointer;
  border-radius: 12.5px;
  padding: 3px 20px;
}
.FilterTable {
  margin-bottom: 20px;
  // display: flex;
  // position: relative;
  justify-content: space-between;
  .FilterItemDiv {
    display: flex;
    gap: 20px;
  }
}

.Filters {
  // position: relative;
  cursor: pointer;
  .Item {
    display: flex;
    align-items: center;
    // background: #cdd9ff;
    background: var(--details-tab-bg);
    box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
    height: 34px;
    padding: 0 10px;
    border-radius: 20px;
    min-width: 160px;
    width: fit-content;
    justify-content: space-between;
    svg path {
      fill: var(--jobs-arrow);
    }
    .items {
      gap: 5px;
      display: flex;
    }
    .Name {
      // color: #737373;
      color: var(--joblist-filter);
      font-size: 12px;
      font-weight: 500;
    }
    .FilterItems {
      // color: #0f0c2f;
      color: var(--details-head);
      font-size: 12px;
      font-weight: 600;
    }
  }
}
.ProjectManager {
  .FilterOptions {
    height: 180px;
    overflow: auto;
  }
}
.filterpop {
  max-width: 320px !important;
  min-width: 320px !important;
}
.FilterOptions {
  max-width: 300px;
  min-width: 120px;
  background-color: var(--details-card-bg);
  border: 1px solid #cdd9ff;
  color: var(--details-tab-content);
  position: absolute;
  margin-top: 20px;
  box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
  border-radius: 6px;
  z-index: 101;
  width: fit-content;
  .Options {
    input {
      border: none;
      background-color: var(--details-card-bg);
      color: var(--details-tab-content);
      font-size: 16px;
      font-weight: 500;
      width: -webkit-fill-available;
      &::placeholder {
        color: var(--option-placeholder);
      }
    }
  }
  .GoButton {
    padding: 5px 20px;
    text-align: right;
    // font-size: 12px;
    // font-weight: 800;
    // color: var(--jobs-arrow);

    border: 1px solid #0035f0;
    background: #f5f7ff;
    font-size: 12px;
    color: #0035f0;
    width: fit-content;
    cursor: pointer;
    border-radius: 12.5px;
    // padding: 3px 20px;
    margin: auto;
    margin-right: 10px;
    margin-top: 10px;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .Options {
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    // margin-bottom: 20px;
    &.Active {
      background: #0035f00d;
    }
  }
  .Option {
    color: var(--details-head);
    font-size: 14px;
    font-weight: 600;
  }
}
.Filter-checkbox {
  // width: 10px;
  // height: 10px;
  // border: 1px solid #0035f060;
  border: 1px solid #000000;
  min-width: 12px;
  height: 11px;
  border-radius: 3px;
  background: #fff;
  &.Active {
    // background-color: #0035f060;
    background: url("./assets/images/ICO\ Tick\ Selector.png") no-repeat,
      #e61855;
    background-size: cover;
    border: 1px solid#E6185500 !important;
  }
}

.ViewButton {
  border: 1px solid #0035f0;
  background: #f5f7ff;
  font-size: 12px;
  color: #0035f0;
  width: fit-content;
  cursor: pointer;
  border-radius: 12.5px;
  padding: 3px 20px;
}

.chargeoutcircle {
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: rgba(242, 245, 254, 1);
  display: flex;
  justify-content: center;
  img {
    width: 17px;
  }
}

.JobTable {
  overflow: auto;
  table {
    width: 100%;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0 15px;
    tbody {
      &::after {
        content: "";
        display: block;
        height: 10px;
      }
      tr {
        cursor: pointer;
        // height: 60px;
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1490196078);
        background-color: var(--card-bg);
        border-radius: 5px;
        margin-bottom: 10px;
        td {
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
          color: var(--Table-Field-Names);
          padding: 5px 10px;
          min-width: 80px;
          min-height: 46px;

          &.Profile {
            img {
              width: 30px;
              height: 30px;
            }
            svg {
              width: 30px;
              height: 30px;
              path {
                fill: var(--Table-Field-Names);
              }
            }
          }
          &:first-child {
            padding-left: 25px;
            border-radius: 5px 0 0 5px;
            border-left: 1px solid var(--Job-Table-border);
            display: flex;
            align-items: center;
            gap: 20px;
          }
          &:last-child {
            border-radius: 0 5px 5px 0;
            border-right: 1px solid var(--Job-Table-border);
          }
        }
        &.Negative {
          td:first-child {
            border-left: 4px solid rgba(255, 49, 49, 0.7);
            .Negative {
              display: block;
            }
          }
          &:hover {
            td:first-child {
              // border-left: 10px solid rgba(255, 49, 49, 0.7);
              .Negative {
                display: block;
              }
            }
          }
        }
      }
    }
    thead {
      tr {
        th {
          font-size: 14px;
          line-height: 18px;
          font-weight: 600;
          color: #8c8c8c;
          .custom-resize-handle {
            z-index: 99 !important;
          }
        }
      }
    }
  }
}
.resetShowPass {
  position: absolute;
  margin-left: -50px;
  margin-top: 12px;
}
.settingresetShowPass {
  position: absolute;
  margin-left: -50px;
  margin-top: 12px;
}
.ProjectChartres {
  display: none;
}
.labourchargeoutcard {
  // margin-top: -65px;
}
@media screen and (max-width: 1320px) {
  .ProjectChartdesk {
    // display: none;
  }
  .ProjectChartres {
    display: block !important;
  }
  .projectcostsec {
    // flex-wrap: wrap;
  }
  .labourchargeoutcard {
    margin-top: 10px;
    margin: 5px;
  }
  .LabourInvoice .projectcostsec {
    // flex-wrap: wrap;
  }
  .ProjectChartDiv {
    margin: 5px;
  }
  Button.MuiButtonBase-root {
    font-weight: 500 !important;
    word-spacing: 2.2px;
  }
}
.overflow-hidden {
  overflow: hidden;
}

.AddJobs {
  background-color: var(--setting-input-background);
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--jobs-arrow);
  font-size: 12px;
  font-weight: 600;
  border: 1px solid #cdd9ff;
  box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
  border-radius: 20px;
  padding: 8px 10px;
  cursor: pointer;
  margin-right: 5px;
}
.AddJobForm {
  position: absolute;
  right: 0px;
  height: 100vh;
  top: 0px;
  // width: 100vw;
  // background: #00000033;

  .AddJobsComp {
    max-height: 650px;
    height: 90vh;
    overflow: auto;
    position: fixed;
    left: 40%;
    top: 20%;
    right: 20px;
    top: 18%;
    bottom: 10px;
    box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
    // background-color: #fff;
    background-color: var(--details-card-bg);
    border: 1px solid #cdd9ff;
    border-radius: 10px;
    padding: 20px;
    width: 643px;
    z-index: 200;
    &.JobComp {
      // overflow: hidden;
    }
    &.addvar {
      width: 600px !important;
    }
    &.DateRangeFilter {
      position: unset !important;
      width: 400px;
      padding: 20px 0;
      height: 80vh !important;
      //  left: auto !important;
      //  top: auto !important;
    }
    &.AddVariation {
      padding-top: 0;
      height: auto;
      input:disabled {
        opacity: 0.4;
      }
      .InputDiv.First {
        margin-top: 70px;
      }
      .ClearSec {
        position: fixed;
        background-color: var(--sidebar-bg);
        border-bottom: 1px solid var(--sidebar-border);
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        display: wi;
        width: inherit;
        padding-right: 0;
        z-index: 100000;
      }
    }
    .addupload {
      justify-content: space-between;
      align-items: center;
      padding-right: 20px;
    }
    .Close {
      padding: 20px;
      text-align: end;
      color: var(--details-head);

      cursor: pointer;
    }
    .materialinput {
      padding: 10px !important;
      .Input {
        width: 270px !important;
      }
      .Head {
        margin-bottom: 15px !important;
      }
      .SearchResults {
        top: 90px !important;
        padding: 20px;
        right: 10px;
        width: 520px;
        height: -webkit-fill-available;
        min-height: 210px;
        .SearchItemDiv {
          height: 150px;
          overflow: auto;
        }
        .addproduct {
          display: flex;
          gap: 10px;
          bottom: 0px;
          input {
            background: var(--login-input);
            border: 0.5px solid rgba(128, 128, 128, 0.5490196078);
            padding: 10px;
            border-radius: 10px;
            width: -webkit-fill-available;
            color: var(--details-head);
            font-size: 16px;
            font-weight: 400;
          }
          .addbtn {
            width: 70px !important;
            // height: 30px;
            padding: 10px;
            width: -webkit-fill-available;
            color: var(--details-head);
            background-color: var(--edit-job-bg);
            display: flex;
            justify-content: center;
            border-radius: 5px;
            font-size: 14px;
            font-weight: 600;
          }
        }
        .selectlist {
          display: flex;
          margin-bottom: 20px;
          cursor: pointer;
          align-items: center;
          gap: 5px;
          font-size: 14px;
          .selectsearch {
            width: -webkit-fill-available;
          }
          .selectdelete {
            border: 1px solid transparent;
            border-radius: 2px;
            width: 20px;
            height: 20px;
            padding: 0;
            svg {
              margin-left: -2px;
            }
          }
          .selectedit {
            background-color: #0035f0;
            border: 1px solid transparent;
            border-radius: 2px;
            width: 20px;
            height: 20px;
            margin-right: 5px;
            padding: 0;
            svg {
              margin-left: -2px;
            }
          }
        }
      }
    }
    .revenuesec {
      padding: 10px !important;
      .Input {
        width: 173px !important;
      }
      .Head {
        margin-bottom: 15px !important;
      }
    }
    .InputDiv {
      cursor: pointer;
      padding: 10px 20px;
      display: flex;
      // align-items: center;
      justify-content: space-between;

      color: var(--details-tab-content);
      .Head {
        color: var(--details-head);
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .Input {
        width: 317px;
        input,
        select {
          border: 0.5px solid #8080808c;
          padding: 10px;
          border-radius: 10px;
          width: -webkit-fill-available;
          color: var(--details-head);
          font-size: 14px;
          font-weight: 400;
          background: var(--login-input);
        }
      }
      &.active {
        border-left: 4px solid #0035f0;
        background: #0035f01a;
      }
      &.MainVariationName {
        align-items: center;
        gap: 20px;
        .Input {
          width: 200px;
        }
      }
    }
  }
}
.CreateJobButton {
  padding: 12px 0;
  text-align: center;
  button {
    background-color: #0035f0;
    padding: 8px 0;
    width: 153px;
    box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 700;
  }
}
.JobDetailsHeaderTitle {
  color: var(--card-head);
  font-size: 16px;
  font-weight: 700;
  width: 100%;
  display: flex;
  align-items: center;
}
.MainSidebar {
  z-index: 1;
}
@media screen and (max-width: 1200px) {
  Header {
    position: fixed;
    left: 0px !important;
    right: 0;
    width: -webkit-fill-available !important;
  }
  .MainSidebar {
    z-index: 999;
    // position: relative;
  }
  .MainComp {
    width: auto !important;
  }
}
@media screen and (min-width: 1430px) {
  .ProjectChartDiv {
    height: 331px !important;
  }
}

.labourStatBar {
  .MuiLinearProgress-determinate {
    width: 200px !important;
  }
}
.labourcard .LabourStat {
  min-width: 46px;
}
@media screen and (max-width: 1200px) and (min-width: 700px) {
  .StatCard:not(.VariationStatCard) {
    width: 230px !important;
  }

  .labourStatBar {
    .MuiLinearProgress-determinate {
      width: 168px !important;
    }
  }
  .labourcard .LabourStat {
    gap: 10px;
  }
  .labourcard {
    padding: 10px;
  }
}
// .deskdetailsheader{
//   display: block;
// }
.mobiledetailsheader {
  display: none !important;
}
.FilterTable {
  flex-wrap: wrap;
  // overflow: auto;
  align-items: center;
  gap: 10px;
  position: relative;
  .FilterItemDiv {
    overflow: auto;
    align-items: center;
  }
}
@media screen and (max-width: 560px) {
  .breadcrumb-nav {
    margin-top: 40px !important;
  }
  .QuotationSheetpopup {
    right: 30px;
  }
  .InfoComp .InfoText {
    position: absolute;
    top: 40px !important;
    z-index: 999 !important;
    left: 0px !important;
  }
  .FilterOptions {
    margin-top: 30px;
    left: 50%;
    transform: translate(-50%, -10%);
    position: fixed;
  }

  .Variation .variation {
    justify-content: center;
  }
  .detailsfilter {
    justify-content: left !important;
  }
  .AddJobForm .AddJobsComp.DateRangeFilter {
    width: 376px;
  }

  .AddJobForm .AddJobsComp {
    right: 12px;
  }

  .UploadSec {
    margin-top: 50px !important;
    margin-bottom: 50px;
  }

  .detailsname {
    .css-1gsv261 {
      // width: auto;
      // overflow-x: auto;
      // overflow-y: hidden;
    }
  }
  .LabourInvoice .projectcostsec {
    flex-wrap: wrap;
  }
  .ProjectChartDiv {
    .labourcard {
      padding: 20px 3px;
    }
  }
  .StatCard {
    width: 220px !important;
  }

  .deskdetailsheader {
    display: none !important;
  }
  .mobiledetailsheader {
    display: flex !important;
  }
  .headeralign {
    width: -webkit-fill-available;
  }
  .UtilsMain {
    width: -webkit-fill-available;
    justify-content: right;
  }
  .mobiledetailsheader {
    margin: 10px 0px;
    .JobDetailsHeader {
      justify-content: end;
      text-align: end;
    }
  }
  .labourchargeoutcard {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .HeaderPopup {
    right: -40px;
  }
  .HeaderMain {
    flex-wrap: wrap;
  }
}
.headeralign {
  width: -webkit-fill-available;
  justify-content: space-between;
}
@media screen and (max-width: 490px) {
  .login-left form .relative {
    margin-right: 20px;
  }
  .resetShowPass {
    position: absolute;
    margin-left: 260px;
    margin-top: -41px;
  }
}
.CompBarCircle {
  // z-index: 10;
}

.CompBarBox {
  background: #cdd9ff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  color: #0f0c2f;
  font-size: 8px;
}

.StatTab {
  cursor: pointer;
  width: 100px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
  border-radius: 5px;
  align-items: center;
  gap: 5px;
  padding: 0px 5px;
  &.NoStatus {
    background: #eeeeee50;
    color: #888;
    border: 1px solid #88888850;
  }
  &.tbc {
    background: #c7d5ff;
    color: #0f0c2f;
  }
  &.wipNotLoss {
    background: #ff435a;
    color: #fff;
  }
  &.wipNot {
    background: #ffe5e5;
    color: #ff435a;
  }
  &.wip {
    background: #d7f6e0;

    color: #36d462;
  }
  &.upload {
    color: #0035f0;
    border: 1px solid #0035f0;
    background: #f5f7ff;
  }
  &.flagged {
    background: #ff7f50;

    color: #fff;
  }
  &.done {
    background: #ffb800;

    color: #0035f0;
  }
  &.deleted {
    background: #888;
    color: #fff;
  }
}

.InputDivDivider {
  border-bottom: 1px solid var(--input-divider);
  // margin-bottom: 10px;
}
.InputDivDividerstatus {
  border-bottom: 1px solid var(--input-divider);
  // margin-bottom: 10px;
  margin-top: 20px;
}

.CustomInput {
  &.NotActive {
    border: 1px solid red;
  }
  .Head {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
.detailsfilter {
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  align-items: center;
  color: var(--Table-Field-Names);
  svg {
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    align-items: center;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
    margin: 5px;
  }
}
.graphpopup {
  position: absolute;
  background-color: var(--card-bg);
  border: 1px solid var(--popup-border);
  border-radius: 10px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);

  padding: 10px 20px;
  margin-top: 253px;
  // margin-right: -16px;
  z-index: 100;
  min-width: 80px;
  color: var(--Table-Field-Names);
  cursor: pointer;

  p {
    padding: 10px 0px;
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    font-family: "Inter", sans-serif;
  }
}
.detailstablecard {
  .graphpopup {
    p {
      padding: 8px 18px;
    }
  }
  .detailstablefilter {
    border: solid 1px rgba(197, 197, 197, 0.5);
    border-radius: 8px;
    color: var(--Table-Field-Names);
    // width: 126px;
    width: 126px;
    // padding: 0px 5px;
    height: 32px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    p {
      padding-left: 5px;
    }
    p,
    svg {
      opacity: 40%;
    }
  }
  .graphpopup {
    margin-top: 220px;
    margin-right: 0px;
    width: 126px;
    padding: 10px 0px;
  }
}
.css-1vc5zo-MuiCircularProgress-circle {
  stroke: rgba(0, 53, 240, 1) !important;
}
.detailskpibtn {
  background-color: var(--details-tab-bg);
  min-width: 100px;
  // padding: 2px 8px;
  height: 41px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  cursor: pointer;

  font-size: 12px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  font-family: "Inter", sans-serif;
  text-transform: capitalize;
  color: var(--details-tab-content);
}
@media screen and (max-width: 1420px) and (min-width: 1320px) {
  .labourchargeoutcard {
    margin-top: 10px;
  }
}
.ProjectChartDiv {
  height: 400px;
}
@media screen and (max-width: 450px) {
  // header{
  //   z-index: 999;
  // }
  .UploadSec:hover .BackButton {
    z-index: 2;
  }
  .MainSidebar {
    z-index: 999;
    // position: relative;
  }
  .FilterTable {
    .FilterItemDiv {
      overflow: auto;
      align-items: center;
    }
  }
}
.detailsfilter {
  svg {
    path {
      // stroke: var(--login-labels);
    }
  }
  h4 {
    color: var(--login-labels);
  }
}
.css-ff2lqd-MuiCircularProgress-root {
  color: rgba(0, 53, 240, 1);
}

.BlurForm {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  top: 0;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  padding: 20px;
}

.TagOptions {
  border-radius: 5px;
  height: 120px;
  overflow: auto;
}

.InputFieldSearch {
  position: relative;
}
.SearchResults {
  position: absolute;
  top: 60px;
  right: 50px;
  box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
  z-index: 1;
  background: var(--details-card-bg);
  border: solid 1px var(--login-input-border);
  width: 120px;
  padding: 10px;
  border-radius: 4px;
  height: 120px;
  overflow: auto;
  .Item {
    margin-bottom: 5px;
  }
}
@media screen and (min-width: 1444px) {
  .apexcharts-legend-series {
    margin: 0px 15px 0px 0px !important;
  }
  .apexcharts-legend {
    right: 130px !important;
  }
  .ProjectCostChart {
    .detailsfilter {
      margin-bottom: -40px;
      svg {
        margin-left: 0px;
        margin-right: 0px;
        z-index: 2;
      }
      p {
        // margin-left: 0px;
        // margin-right: 0px;
        z-index: 2;
      }
    }
  }
  .LabourInvoice .projectcostsec {
    justify-content: space-between;
    gap: 20px;
  }
  .detailsfilter {
    text-transform: capitalize;
    svg,
    p {
      color: var(--login-labels);
    }
  }
}
// .fixedheightheading{
//   height: 50px !important;
// }

.ClearFilter {
  background: var(--searchbar);
  // color: var(--item-sub-text);
  border: 1px solid #cdd9ff;
  padding: 8px 10px;
  border-radius: 20px;
  font-size: 12px;
  color: var(--joblist-filter);
  min-width: 56px;
}

header {
  position: fixed;
  left: 250px;
  right: 0;
  width: auto;
  // z-index: 999;
  z-index: 200;
}

main {
  margin-top: 80px;
}

.InfoComp {
  .InfoMark {
    padding: 5px 10px;
    cursor: pointer;
    display: flex;
  }
  .InfoText {
    position: absolute;
    top: -70px;
    box-shadow: 0px 1px 3px 1px #00000026, 0px 1px 2px 0px #0000004d;
    background-color: var(--bg-color);
    padding: 10px;
    border-radius: 5px;
    font-size: 12px;
    min-width: 200px;
    width: 200px;
    font-weight: 700;
  }
}
.css-ly7pri-MuiLinearProgress-root {
  z-index: 2 !important;
}
.TableStaticHeading {
  min-width: 213px;
  width: 213px;
}
.TableStaticField {
  max-width: 150px !important;
  min-width: 150px !important;
  margin-left: 10px;
  overflow: hidden !important;
  .DataTable {
    border-right: 0px !important;
    border-radius: 6px 0px 0px 6px !important;
  }
}
.Variation {
  .LabourAnalysis {
    margin-top: 30px;
    .TableStaticHeading {
      padding-right: 30px;
    }
  }
  .variation {
    padding-top: 30px;
    gap: 30px;
    flex-wrap: wrap;
    // display: none;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    color: var(--Table-Field-Names);
    .item {
      font-weight: 600;
      margin-bottom: 10px;
      margin-right: 5px;
    }
  }
  .TableNew .TableFieldData .DataTable.Heading .Item {
    min-width: 140px;
    &:first-child {
      border-right: none;
    }
  }
  .TableNew .TableFieldData .DataTable .Item {
    color: var(--Table-Field-Names);
    min-width: 140px;
  }
}
.Reports table thead tr th {
  min-width: 80px;
}
.detailskpibtn.variationbtn {
  transition: background-color 0.3s ease;
  border-radius: 15px;
  margin: 0px 0px 3px 0px;
  height: 41px;
  // padding: 10px 15px;
  background: rgb(255, 255, 255);
  color: rgb(15, 12, 47) !important;
  font-size: 12px;
  font-weight: 800 !important;
  line-height: 14.52px;
  font-family: "Inter", sans-serif;
  // box-shadow: -3px 2px 3px 0px rgba(0, 0, 0, 0.15), 1px 0px 3px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}
.KPISummarytext {
  color: var(--Table-Field-Names);
  margin-top: 40px;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  font-size: 16px;
}
.kpisummerysubtext {
  color: var(--Table-Field-Names);
  margin-top: 10px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 12px;
}
@media screen and (min-width: 330px) and (max-width: 480px) {
  .StatCard {
    min-width: -webkit-fill-available !important;
  }
  .UploadBar {
    margin-left: 25px;
    position: absolute;
    top: 40px;
    right: 0px;
  }
}
@media screen and (max-width: 330px) {
  .MuiLinearProgress-colorPrimary {
    width: 168px !important;
  }
  .UploadBar {
    margin-left: -20px;
  }
}
@media screen and (min-width: 1400px) {
  .menu-item.logout {
    position: absolute;
    top: auto;
    bottom: 40px;
  }
}
@media screen and (max-width: 1000px) {
  .QuotationSheetpopup {
    right: 30px;
  }
}

.TableNewVariation {
  width: 100% !important;
}

.QuotationSheet {
  // background: rgb(229 234 253);
  background: var(--quote-sheet-card);
  border-radius: 15px;
  padding: 10px;
  color: var(--jobs-arrow);
  gap: 30px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  height: 23px;
  width: 140px;
  margin-left: auto;
  cursor: pointer;
  justify-content: end;
  // position: relative;
}
.QuotationSheetsvg {
  box-shadow: 0px 4px 6px 0px rgba(0, 53, 240, 0.2);
  // background: var(--quote-sheet-bg);
  background: #fff;
  border-radius: 100px;
  min-height: 28px;
  min-width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.QuotationSheetpopup {
  position: absolute;
  // background-color: var(--card-bg);
  z-index: 100;
  max-width: 200px;
  min-width: 120px;
  background-color: var(--card-bg);
  position: absolute;
  margin-top: 20px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1490196078),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3019607843);
  border: 1px solid #cdd9ff;
  border-radius: 10px;
  width: fit-content;
  margin-top: 10px;
  overflow: hidden;
  .options {
    color: var(--Table-Field-Names);
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .Active {
    background: var(--quote-sheet-activebg);
  }
}
.RedPer {
  color: #ff3b30;
}
.GreenPer {
  color: #36d462;
}

.AddButton {
  font-size: 14px;
  font-weight: 600;
  color: var(--menu-item-active);
  margin: 20px 0 20px auto;
  cursor: pointer;
  width: fit-content;
}

.ErrNoti {
  color: #ff0000 !important;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
  &.active {
  }
}

.EditJob {
  color: var(--edit-job);
  background: var(--edit-job-bg);
  width: fit-content;
  padding: 5px;
  border-radius: 3px;
  display: flex;
  &:hover {
    background: var(--edit-job-bghover);
  }
}
@media screen and (max-width: 400px) {
  .TableStaticHeading {
    min-width: 140px;
    width: 140px;
  }
  .AddJobForm .AddJobsComp {
    width: -webkit-fill-available !important;
    right: 12px;
    left: 12px;
    .InputDiv {
      display: block;
      .Input {
        width: -webkit-fill-available;
        margin-top: 10px;
      }
    }
  }
}
@media screen and (min-width: 401px) and (max-width: 600px) {
  .AddJobForm .AddJobsComp {
    width: -webkit-fill-available !important;
    left: 12px;
    .Input {
      width: 260px !important;
    }
  }
}
@media screen and (max-width: 325px) {
  .ProjectChartDiv {
    width: 80%;
  }
}
@media screen and (max-width: 360px) {
  .labourStatBar .MuiLinearProgress-determinate {
    width: 176px !important;
  }
}
@media screen and (max-width: 940px) {
  .FilterTable {
    justify-content: end;
  }
}

.ExportDiv {
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: flex-end;
  width: 100%;

  .button-group {
    display: flex;
    gap: 12px;
    padding: 0px 16px;
  }

  .button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: var(--searchbar);
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    font-size: 14px;
    color: var(--filter-button);
    cursor: pointer;
    transition: background-color 0.2s;
    position: relative;
    font-weight: bold;
    &.Active {
      color: #fff;
      background-color: #ff3b30;
    }
  }

  .button:hover {
    // background-color: #f9fafb;
  }

  .icon {
    width: 16px;
    height: 16px;
    stroke: currentColor;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
  }

  .badge {
    position: absolute;
    top: -6px;
    right: -6px;
    min-width: 18px;
    height: 18px;
    background-color: #3b82f6;
    color: white;
    font-size: 12px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    font-weight: 500;
    border: 2px solid white;
  }

  .export {
    display: flex;
    align-items: center;
    color: var(--tab-text-color-active);
    cursor: pointer;
    gap: 10px;
    /* border: 1px solid #1814f370; */
    padding: 4px;
    border-radius: 2px;
    // box-shadow: #1814f350 0px 1px 4px;
    // background: var(--searchbar);;

    justify-content: center;
  }
}
.DetailsTabs {
  .Mui-selected {
    transition: background-color 0.3s ease;
    border-radius: 17px;
    margin: 13px 5px;
    height: 24px;
    min-height: 24px;
    background: rgba(255, 255, 255, 1);
    color: rgba(15, 12, 47, 1) !important;
    font-family: "Inter", sans-serif;
    font-size: 12px;
    font-weight: 800 !important;
    line-height: 14.52px;
    font-family: "Inter", sans-serif;
    box-shadow: 0px 4px 4px 0px rgba(0, 53, 240, 0.2);
  }
}

.MaterialStat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.material {
  .KPISummarytext {
    margin-top: 10px;
  }
}

.equipment {
  .TableFieldData {
    width: 100%;
    max-width: none !important;
  }
  .DataTable {
    width: auto !important;
    .Item {
      &.Total {
        width: 100%;
      }
    }
  }
  // .TableStaticField {
  //   min-width: auto !important;
  //   max-width: none !important;
  // }
  // .DataTable{
  //   height: fit-content !important;
  //   .Item{
  //     width: 50%;
  //   }
  // }
}
.SelectManager {
  .InputDiv {
    .SearchResults {
      right: auto;
    }
    input {
      font-size: 12px;
      color: var(--item-sub-text);
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
      border: 1px solid #cdd9ff;
      padding: 8px 10px;
      border-radius: 6px;
      width: 80px;
      background: var(--searchbar);
      &::placeholder {
        font-size: 12px;
        color: var(--searchbar-placeholder);
        font-weight: 500;
      }
    }
  }
}

.Quotation {
  .TableFieldData {
    width: 100%;
    .DataTable {
      width: auto;
      .Item {
        width: 100%;
      }
    }
  }

  .vertical-tabs-container {
    display: flex;
    height: 60vh;
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: auto;
  }

  .tabs {
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--quotation-tabs);
    border-right: 1px solid #ccc;
    width: 200px;
    min-width: 200px;
  }

  .tab-button {
    padding: 12px 16px;
    text-align: left;
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--login-labels);
  }

  .tab-button:hover {
    background-color: var(--quotation-tabs-hover);
  }

  .tab-button.active {
    background-color: #d1e7dd;
    font-weight: bold;
    color: #184ed8;
  }

  .tab-content {
    flex: 1;
    padding: 20px;
    background-color: var(--details-card-bg);
  }
}
.filterjobs {
  cursor: pointer;

  // position: relative;
}
// .jobpopupfilter {
//   width: 500px;
//   position: fixed;
//   cursor: pointer;
//   padding: 20px;
//   border: 1px solid #cdd9ff;
//   border-radius: 20px;
//   color: var(--details-tab-content);
//   top: 14%;
//   right: 10px;
//   z-index: 999;
//   height: 650px;
//   background-color: var(--details-card-bg);
//   .closePopupJob {
//     height: 50px;
//     align-items: center;
//     display: flex;
//     justify-content: end;
//   }
// }

// @media screen and (min-width: 321px) and (max-width: 520px) {
//   .jobpopupfilter {
//     width: 410px;
//     height: -webkit-fill-available;
//   }
// }
// @media screen and (max-width: 320px) {
//   .jobpopupfilter {
//     width: 310px;
//     height: -webkit-fill-available;
//   }
// }

.JobsFilter {
  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .Content {
    height: 500px;
    overflow: auto;
    .Section {
      font-size: 21px;
      font-weight: 600;
      color: #222;
      padding: 20px 0px 10px 0px;
      color: var(--menu-text-color);
    }
    .statusoptionalign {
      gap: 20px;
      flex-wrap: wrap;
    }
    .statusoption {
      border: 1px solid var(--details-input-border);
      border-radius: 17px;
      height: 31px;
      padding: 2px 12px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      // color: #222222;
      color: var(--details-tab-content);
      gap: 4px;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 14px;
      font-family: "Inter", sans-serif;
      font-weight: 500;
      cursor: pointer;
    }
    .statusoption.active {
      background-color: var(--filter-status-option);
      border: solid 1px #4682b4 !important;
    }
  }
  .Second {
    justify-content: space-between;
    display: flex;
  }
  .popup-content {
    display: grid;
    gap: 20px;
    // background: white;
    background-color: var(--details-card-bg);
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    position: relative;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
}
.filterjobicon {
  display: flex;
  font-size: 14px;
  color: var(--logo-color);
  align-items: center;
  gap: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 2px;
  border: 1px solid #cdd9ff;
  padding: 5px 17px !important;
  border-radius: 20px;
  background: var(--searchbar);
}
.dropdown-select {
  height: 40px;
  border: solid 1px transparent;
}
.dropdown-select:hover {
  border-color: transparent;
}

.dropdown-select:focus {
  border-color: transparent;
  // box-shadow: 0 0 0 2px rgba(0, 86, 179, 0.2);
}
.dropdown-select option {
  padding: 10px;
  font-size: 18px;
  height: 40px;
  background-color: #fff;
  // color: var(--logo-color);
}
.dropdown-select option:hover {
  background-color: #f0f8ff;
}

.filtersearch {
  display: flex;
  gap: 20px;
  justify-content: end;
}
.clearall {
  border-color: transparent;
  background: none;
  font-size: 18px;
  color: var(--details-tab-content);
  font-weight: 600;
  cursor: pointer;
}
.filterupload {
  background-color: var(--filter-upload);
  color: #fff;
  width: 100px;
  height: 50px;
  font-size: 18px;
  border-radius: 10px;
  cursor: pointer;
  letter-spacing: 1.4px;
  border-color: transparent;
}
.statusoptionspace {
  gap: 30px;
  flex-wrap: wrap;
  padding: 10px 0px;
}
.popup-close {
  border-color: transparent;
  background: none;
  font-size: 24px;
  color: var(--details-tab-content);
  font-weight: 600;
  cursor: pointer;
  z-index: 999;
}
.Heading.clearall {
  position: absolute;
}

.JobsFilter {
  .statusoption {
    &.active {
      border: 1px solid #000000;
    }
  }
}

.modal-overlay-log{
  position:absolute;
  right: 0;
  top: 30px;
width: fit-content;
height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;
  .modal-content {
    background: white;

    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.2s ease-out;
    font-size: 14px;
  }
}

.modal-overlay-Deletevar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.2s ease-out;

  .modal-content {
    background: white;
    padding: 24px;
    border-radius: 8px;
    min-width: 300px;
    max-width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.2s ease-out;
  }

  .modal-content h2 {
    margin: 0 0 16px 0;
    font-size: 1.25rem;
    font-weight: 600;
    color: #333;
  }

  .modal-content p {
    margin: 0 0 24px 0;
    color: #666;
    line-height: 1.5;
  }

  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }

  .cancel-button {
    padding: 8px 16px;
    border: 1px solid #ddd;
    background: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
  }

  .cancel-button:hover {
    background: #f5f5f5;
    border-color: #ccc;
  }

  .delete-button {
    padding: 8px 16px;
    border: none;
    background: #ff435a;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
  }

  .delete-button:hover {
    background: #ff2845;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideIn {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
.daterange {
  justify-content: space-between;
  padding: 10px;
  div,
  label {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 0px;
    font-family: "Inter", sans-serif;
    color: var(--details-tab-content);
  }
}
.clrdelete {
  margin-left: 10px;
  color: #7776b1;
  font-size: 14px;
}
.tagselectalign {
  gap: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.tagselectalign input[type="checkbox"] {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid #4a90e2;
  border-radius: 4px;
  margin-right: 8px;
  transition: all 0.3s ease;
  background-color: transparent;
  cursor: pointer;
}

.tagselectalign input[type="checkbox"]:checked + .tagselector::before {
  background-color: #1a1b1c;
  // border-color: #4a90e2;
  position: relative;
}

.tagselector {
  display: inline-block;
  // padding: 6px 12px;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  user-select: none;
  color: #222;
  cursor: pointer;
  position: relative;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: var(--details-tab-content);
}

.date-range-container {
  max-height: 160px;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.date-range-container.expanded {
  max-height: 1000px;
}

.toggle-button {
  margin-top: 10px;
  padding: 0px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  background-color: var(--details-card-bg);
  color: var(--show-more-text);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: underline;
  transition: background-color 0.3s ease;
}

.Options {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.create-button {
  background-color: #111c44;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  transition: background-color 0.3s ease;
}

.create-button:hover {
  background-color: #111c44;
}

.create-input {
  padding: 8px;
  border: 1px solid #cdd9ff;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  width: 200px;
  outline: none;
}
.date-input {
  padding: 8px;
  border: 1px solid #cdd9ff;
  border-radius: 4px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  width: 200px;
  outline: none;
  transition: border-color 0.3s ease;
}

.date-input:focus {
  border-color: #4a90e2;
}
.filter-count {
  // background-color: red;
  color: rgb(255, 0, 0);
  font-size: 8px;
  font-family: "Inter", sans-serif;
  border: 1px solid rgb(255, 0, 0);
  padding: 0px 3px;
  // font-weight: bold;
  border-radius: 50%;
  text-align: center;
}

.statusoption {
  margin-right: 10px;
}

.SelectedQuote {
  font-size: 8px !important;
  font-family: "Inter", sans-serif;
}

.ReportCheckBox {
  min-width: auto !important;
  span {
    width: 15px;
    height: 15px;
    border: 1px solid #1814f3;
    border-radius: 3px;
    &.Active {
      background: var(--details-tab-bg);
    }
  }
}

.ReportCheckBoxActive {
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.5) !important;
}
.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  gap: 10px;
}

.checkbox {
  width: 15px;
  height: 15px;
  border: 2px solid var(--chebox-border);
  border-radius: 4px;
  transition: background-color 0.2s, border-color 0.2s;
}

.checkbox.selected {
  background-color: #e61855;
  border-color: #e61855;
}

.checkbox.selected::after {
  content: "✔";
  color: rgb(255, 255, 255);
  font-size: 14px;
  margin-top: -2px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.dropdown {
  position: relative;
  width: 100%;
  font-family: "Inter", sans-serif;
}

.dropdown-header {
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: var(--details-tab-content);
  padding: 10px;
  // border: 1px solid #ccc;
  border: 1px solid var(--details-input-border);
  // background: white;
  background-color: var(--details-card-bg);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
}

.arrow {
  font-size: 12px;
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 4px;
  width: 91%;
  border: 16px solid transparent;
  // background: white;
  background-color: var(--details-card-bg);
  box-shadow: 1px 8px 10px 2px rgba(0, 0, 0, 0.2);
  max-height: 200px;
  overflow-y: auto;
  z-index: 100;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  gap: 10px;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  color: var(--details-tab-content);
}

.dropdown-item:hover {
  background: var(--menu-active-bg);
}

.daterange.active {
  // background-color: #cccccce6;
  background-color: var(--menu-active-bg);
  padding: 10px 10px;
  cursor: pointer;
}

.Resizable-cell-utils {
  width: 100% !important;

  .react-resizable-handle {
    width: 100% !important;
  }
}
.react-resizable-handle {
  display: none !important;
}

.hover-bar:hover {
  opacity: 1 !important;
}

.custom-resize-handle:hover + .hover-bar {
  opacity: 1;
}
@media print {
  body {
    -webkit-print-color-adjust: exact;
  }

  .print-content {
    width: 1200px;
    max-width: 1200px;
    overflow: visible !important;
  }

  .table-container-print {
    width: 1200px;
    max-width: 1200px;
    overflow: hidden;
    page-break-inside: auto;
  }

  .table-container-print table {
    width: 1200px;
    max-width: 1200px;
    table-layout: fixed;
  }

  .table-container-print th,
  .table-container-print td {
    padding: 5px;
  }
  .ReportCheckBox {
    .Filter-checkbox {
      display: none;
    }
  }
}

@page {
  size: A4 landscape;
  margin: 10mm;
}
.exportbtn {
  border: solid 1px;
  padding: 5px 15px !important;
  border-radius: 50px !important;
}

.SortingButton {
  cursor: pointer;
  position: relative;
  z-index: 100;
  margin-bottom: -4px;
  width: 40px;
}

@media screen and (max-width: 430px) {
  .JobsFilter .popup-content {
    width: 80%;
  }
  .JobsFilter .Content .statusoptionalign {
    gap: 10px;
  }
  .dropdown-list {
    width: 86%;
  }
}

.filter-divider {
  border: none;
  border-top: 1px solid #eee;
  margin: 16px 0;
}

.SelectedFiltersContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
  margin-top: 16px;
  background-color: var(--card-bg);
  padding: 10px 15px;
  border: 1px solid var(--border-color);
  border-radius: 6px;
}

.SelectedFilterTab {
  display: inline-flex;
  align-items: center;

  background-color: var(--menu-active-bg);
  color: var(--details-tab-content);
  border: 1px solid var(--border-color);
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.SelectedFilterTab:hover {
  background-color: var(--details-card-bg);
}

.FilterLabel {
  margin-right: 8px;
}

.RemoveFilter {
  font-size: 1rem;
  line-height: 1;
  color: var(--details-tab-content);
}

.ClearAllBtn {
  margin-left: auto;
  padding: 6px 16px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  background-color: var(--searchbar);
  color: var(--tab-text-color-active);
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ClearAllBtn:hover {
  background-color: var(--searchbar-placeholder);
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0); /* Semi-transparent */
  z-index: 5;
}
.jobsliststatus {
  z-index: 10000;
  position: absolute;
  background-color: var(--details-card-bg);
  border: 1px solid #cdd9ff;
  color: var(--details-tab-content);
  // padding: 10px;
  width: 100px;
  border-radius: 8px;
  margin-top: 6px;
  div.statusoptions {
    padding: 10px;
  }
}
.statusoptions:hover {
  background: var(--menu-active-bg);
  border-radius: 8px;
}
.AddJobForm .AddJobsComp .InputDiv .Input input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
  -webkit-appearance: textfield; /* Chrome, Safari, Edge */
  appearance: textfield;
}

.AddJobForm
  .AddJobsComp
  .InputDiv
  .Input
  input[type="number"]::-webkit-inner-spin-button,
.AddJobForm
  .AddJobsComp
  .InputDiv
  .Input
  input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}
.Notificationbtn {
  display: flex;
  align-items: center;

  height: 34px;
  padding: 0 20px;
  border-radius: 20px;

  justify-content: space-between;
  color: var(--details-head);
  font-size: 12px;
  font-weight: 600;
  position: relative;
  .badge {
    position: absolute;
    top: -1px;
    right: 10px;
    min-width: 8px;
    height: 14px;
    background-color: #ff6666 !important;
    color: white;
    font-size: 10px;
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 4px;
    font-weight: 500;
    border: 1px solid white;
  }
}
.NotificationPopup {
  position: absolute;
  width: 300px;
  // min-width: 470px;
  // min-width: 270px;
  max-height: 70vh;
  // height: 469px;
  overflow: auto;
  position: fixed;
  right: 20px;
  top: 70px;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1490196078),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3019607843);
  background-color: var(--details-card-bg);
  border: 1px solid #cdd9ff;
  border-radius: 20px;
  z-index: 200;
  padding: 20px;
  .InputDiv {
    cursor: pointer;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 10px;
    color: var(--details-tab-content);
  }
}

.notiCard {
  background: var(--details-card-bg);
  // border: 1px solid #cdd9ff;
  // min-width: 430px;
  border-radius: 20px;
  align-items: center;
  width: fit-content;
  padding: 15px 15px 15px 10px;
  display: flex;
  gap: 60px;
  // margin-bottom: 20px;
  .item {
    color: var(--details-head);
    font-size: 14px;
    font-weight: 600;
    // gap:5px;
    .head {
      transform: rotate(-90deg);
      margin-left: -5px;
    }
    .title {
      font-weight: 500;
      font-size: 12px;
      span {
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}

.wipstart {
  background: #ff435a;
  padding: 5px 10px;
  border-radius: 5px;
}

.labourvar {
  justify-content: start;
  margin-left: -8px;
}

.edit-panel {
  top: 50%;
  position: absolute;
  background: var(--quote-sheet-bg);
  z-index: 100;
}
.UploadBar {
  width: fit-content;
  margin: auto;
  margin-right: 0;
}
.VariationStatCard {
  width: 100%;
  gap: 10px;
  .ItemStatVar {
    width: 100%;
  }
  .VariationStatCardColumn {
    width: 50%;
    .cardcontent {
      // height: 40px;
    }
  }
}
.variationStat {
  flex-wrap: nowrap !important;
}
/* quill-dark.css */
.quill-dark .ql-toolbar {
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
  color: var(--table-td) !important;
  .ql-picker-label {
    color: var(--table-td) !important;
    svg {
      polygon {
        stroke: var(--table-td) !important;
      }
    }
  }
  .ql-editor.ql-blank::before {
    color: var(--table-td) !important;
  }
  .ql-formats {
    button {
      svg {
        path {
          stroke: var(--table-td) !important;
        }
      }
      svg {
        line {
          stroke: var(--table-td) !important;
        }
      }
      svg {
        polygon {
          stroke: var(--table-td) !important;
        }
      }
    }
  }
}
.quill-dark {
  min-height: 100px;
  height: 60%;
  .ql-editor.ql-blank::before {
    color: unset !important;
  }
}
.quill-dark .ql-container {
  height: 70%;
  background-color: var(--card-bg);
  border: 1px solid var(--border-color);
}

.quill-dark .ql-editor {
  background-color: var(--card-bg);
  color: var(--table-td) !important;
}
.DateSelectVar {
  position: relative;

  input {
    position: absolute;
    left: 0;
    opacity: 0;
    width: 15px;
  }
}
.DatePickerVr {
  // min-width: 120px !important;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
  display: flex;
  justify-content: space-between;
  border: 1px solid var(--details-input-border);
  border-radius: 5px;

  &.Second {
    // margin-right: 20px;
  }
  .DatePickerVrItem {
    padding: 5px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.breadcrumb-nav {
  position: fixed;
  z-index: 100;
  width: 100%;
  margin-left: 5px;

  li {
    font-size: 12px;
    color: var(--item-sub-text);
    font-weight: bold;
    a {
      color: var(--item-sub-text);
    }
  }
}
@keyframes blink {
  50% {
    fill: transparent;
  }
}
.dot {
  animation: 1s blink infinite;
  fill: grey;
}
.dot:nth-child(2) {
  animation-delay: 250ms;
}
.dot:nth-child(3) {
  animation-delay: 500ms;
}

.loader-variation {
  color: grey;
}
.CostLabourAdjustedInput {
  padding: 0 5px 0 10px;
  width: 80%;
  border: 1px solid var(--setting-input);
  background: var(--setting-input-background);
  border-radius: 8px;
  height: 30px;
  input {
    width: 80%;
    border: none;
    background: none;
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: var(--details-card-content);
  }
}

.invoice-detail-toggle {
  color: var(--Table-Field-Names);
  width: -webkit-fill-available;
  height: 200px;
  .toggle-body {
    overflow: auto;
  }
  th {
    font-size: 14px;
    line-height: 16px;
    min-width: 100px;
    text-align: left !important;
    background: var(--menu-active-bg) !important;
  }
  td {
    font-size: 14px;
    min-width: 100px;
    text-align: left !important;
  }
}
.selectedInvoicePeriod {
  margin-bottom: 244px !important;
  .Item {
    cursor: pointer;
  }
}
.InvoiceDetails:not(.variationDetails) {
  position: absolute;
  top: 160px;
  width: -webkit-fill-available;
}
.variationDetails {
  position: absolute;
  top: 10px;
  width: -webkit-fill-available;
}
.DataTable {
  .Item {
    &.active {
      font-weight: bold !important;
    }
  }
}

.detail-card {
  color: var(--Table-Field-Names);
  position: absolute;
  // top: 40px;
  top: -5px;
  height: 200px;
  overflow: auto;
  width: -webkit-fill-available;
  th {
    font-size: 14px;
    line-height: 16px;
    min-width: 100px;
    text-align: left !important;
    background: var(--menu-active-bg) !important;
  }
  td {
    font-size: 14px;
    min-width: 100px;
    text-align: left !important;
  }
}
.Supplier {
  margin-top: 25px;
  .JobTable table tbody tr td:first-child {
    border-left: none;
  }
}
.NoteVariation {
  .DatePickerVr {
    padding: 5px;
    height: 20px;
    width: 120px;
  }
}
.CostLabour {
  display: flex;
  gap: 10px;
  align-items: center;
  button {
    border: 1px solid #0035f0;
    background: #f5f7ff;
    font-size: 12px;
    color: #0035f0;
    width: fit-content;
    cursor: pointer;
    border-radius: 5px;
    padding: 3px 10px;
    font-weight: 600;
  }
}

.manager-floating-dropdown {
  background-color: var(--details-card-bg);
}

.TableNewDraggable {
  .TableFieldData {
    .DataTable {
      .Item {
        &:first-child {
          overflow: auto;
          height: 40px;
          background-color: var(--details-card-bg);
          border-right: 1px solid var(--details-input-border);
          border-top: 1px solid var(---border-invicible);
          border-bottom: 1px solid var(---border-invicible);
          margin-left: -2px;
          position: absolute;
          padding: 0 20px;
          z-index: 99;
          align-items: center;
        }
        // &:not(:first-child){
        //   background-color: #000;
        // }
        &:nth-child(2) {
          margin-left: 20rem;
        }
      }
    }
    .VariationKPIJobDatarow {
      .Item {
        &.Total {
          // border: none;
        }
        // &:not(:first-child){
        //   border-bottom: 1px solid var(--details-input-border);
        //   border-top: 1px solid var(--details-input-border);
        // }
      }
    }
  }
}

.selectedDraggable {
  margin-bottom: 240px !important;
}
.TableNewDraggable {
  .DataTable {
    // height: auto !important;
    // padding: 15px 20px !important;
    padding-left: 0 !important;
    .Item {
      display: flex;
      align-items: center;
      height: auto;
      width: 140px;
      &.Clickable {
        height: 30px;
        overflow: auto;
        align-items: baseline;
      }
    }
  }
}
.FilterGroup {
  // .Filter-checkbox{
  //   &.Active{
  //     background: none !important;
  //   }
  // }
  .OptionsContainer {
    display: flex;
    flex-wrap: wrap;
  }
}
.stat-format-container {
  position: relative;
}

.NegativeLoss {
  margin-right: -5px;
  height: 15px;
  margin-bottom: -15px;
  border-bottom-right-radius: 0;
  margin-left: 5px;
  border-bottom-left-radius: 0;
  min-width: 100px;
}
.DateRangeLabel {
  display: flex;
}
.DateRangeFilterTabs {
  .custom-dates-inline {
    height: 34px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1490196078),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3019607843);
    background: var(--details-tab-bg);

    display: flex;
    align-items: center;
    border-radius: 15px;
    position: relative;
    margin-left: 5px;
    .custom-date {
      cursor: pointer;
      color: var(--details-head);
      font-size: 12px;
      font-weight: 600;
      padding: 0 10px;
      height: -webkit-fill-available;
      position: relative;
      display: flex;
      gap: 10px;
      align-items: center;
      &:not(:last-child) {
        border-right: 1px solid #888888;
      }
      input {
        width: 15px;
        position: absolute;
        left: 0;
        opacity: 0;
      }
      .Label {
      }
      .AddJobForm {
        right: auto !important;
      }
      .Input {
        position: relative;
      }
    }
    .WithDateRange {
      cursor: pointer;
      .Label {
        display: flex;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .PlusVariations {
    flex-wrap: wrap;
    .VariationStatCardColumn {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 560px) {
  .AddJobForm .AddJobsComp.DateRangeFilter {
    width: 280px !important;
  }
  .AddJobForm .AddJobsComp.AddVariation {
    left: auto;
    right: 10px;
  }
}

@media screen and (max-width: 500px) {
  .variationStatValue {
    flex-wrap: wrap !important;
  }
  .notes-section {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 325px) {
  .UploadFileSec .UploadFileSecSvg {
    right: 17px;
  }
}
.statusheading {
  font-size: 18px !important;
}
.FilterOptions .Options.tagoptionsnonact {
  margin: 5px 10px;
  padding: 6px 15px;
  // border: solid 1px #dfeaf2;
  display: flex;
  border-radius: 10px;
  background-color: var(--status-bg);
}
.FilterOptions .tagoptionsnonact.Options.Active {
  background: #e61855;
  border-radius: 10px;
  // margin: 5px;
  .Option {
    color: #dfeaf2;
  }
}
.tagscontainer {
  width: 200px;
}

.Deltacards {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-bottom: 50px;
  .deltacardone {
    width: 550px;
  }
  .deltacardtwo {
    width: 340px;
  }
  .deltacardthree {
    width: 200px;
  }
}

.RevenueModel {
  width: 350px;
}
.AddJobFormDate {
  // position: relative !important;
  .DateRangeFilter {
  }
}
.NewDateRangePopup {
  position: absolute;
  top: 100%;
  margin-top: 10px;

  left: 200px;

  z-index: 999999;
}

.Logger {
  position: fixed;
  top: 0;
    left: 0;
  width: 100%;
  height: 100%;
  color: black;
  background-color: #00000060;
  z-index: 1000000;
  display: flex;
  align-items: center;
  justify-content: center;
  .Mainsection {
    padding: 10px 0;
    border-radius: 6px;
    background-color: white;
    .StatTab {
      width: auto !important;
      border-radius: 3px !important;
    }
  }
  table{
    border-collapse: collapse;
    tr{
      td, th{
        border-bottom: 1px solid var(--details-input-border);
        padding: 10px;
        text-align: left;
      }
      th{
        color: var(--details-Table-Field-Data);
        font-weight: 700;
        font-size: 14px;
        font-family: "Inter", sans-serif;
        &:first-child{
          padding-left: 20px;
        }
        &:last-child{
          padding-right: 20px;
        }
      }
      td{
        color: var(--Table-Field-Names);
        font-size: 14px;
        font-weight: 500;
        font-family: "Inter", sans-serif;
        &:first-child{
          padding-left: 20px;
        }
        &:last-child{
          padding-right: 20px;
        }
      }
    }
  }
}
.PopupOptions{
  margin-left: 10px;

  position: relative;
  svg{
    cursor: pointer;
  }
  .PopupList{

  }
}

.NewUser{
  .ProfileEditPage{
    height: 470px;
    overflow: auto;
    position: fixed;
    left: 20%;
    top: 20%;
    right: 10%;
    top: 18%;
    bottom: 10px;
    box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1490196078), 0px 1px 2px 0px rgba(0, 0, 0, 0.3019607843);
    background-color: var(--details-card-bg);
    border: 1px solid #cdd9ff;
    border-radius: 10px;
    padding: 30px;
.Close{
  margin-bottom: 20px;
    text-align: end;
}
    }
  
}